import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from "./services/auth/auth.service";
import {MainService} from "./services/main/main.service";
import { Meta,Title } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {OcpUtils} from "../OcpUtils";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  notAvailable = false;
  onlyRouterShow = false;
  pathsWithoutHeader: any[] = ['/nachnite-zarabatyvat-vmeste', '/thank-you-page'];

  constructor(private router: Router, private auth: AuthService,
              private meta: Meta,private title: Title,
              public translate: TranslateService,
              private main: MainService) {}

  ngOnInit() {
    this.checkPulse();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onlyRouterShow = this.pathsWithoutHeader.includes(event.url.split('?')[0]);

        setTimeout(() => {
          this.title.setTitle(this.translate.currentLang == 'kz'
            ? 'Ismet Partner — Бизнес серіктестерді табыңыз'
            : 'Ismet Partner — Находите партнёров по бизнесу');
          this.meta.updateTag(
            {
              name: 'description',
              content:
                this.translate.currentLang == 'kz'
                  ? 'Іскерлік ынтымақтастық платформасы: тауарларды жылжыту, бизнес шешімдерін табу, серіктестіктер құру, мәмілелерді тікелей басқару.'
                  : 'Платформа для бизнес-сотрудничества: продвижение товаров, поиск решений для бизнеса, налаживание партнерств, прямое управление сделками'
            }
          );
        } , 1)
      }
    });
    if(typeof window !== 'undefined') {
      window.addEventListener('popstate', this.setCookie);
      window.addEventListener('hashchange', this.setCookie);
      window.addEventListener('load', this.setCookie);
      document.addEventListener('click', this.setCookie);
    }
  }

  allowPath: string[] = ['item', 'favorite', 'catalog', 'account', 'card', 'side-menu'];
  pathCheck(){
    if(typeof window !== 'undefined') {
      for (const path of this.allowPath) {
        if(window.location.pathname.includes(path)) {
          return true
        }
      }
    }
    return false;
  }

  greyPages(){

    let pages = ['tu-order', 'account', 'company'];

    if (typeof window !== 'undefined') {
      return pages.some(path => window.location.pathname.includes(path));
    }

    return false;
  }

  checkPulse(){
    this.main.checkPulse().subscribe({
      next: (res: any) => {
        this.notAvailable = res.isVisible;
      },
      complete: () => {},
      error: () => {}
    });
  }

  ngOnDestroy() {
    if(typeof window !== 'undefined') {
      window.removeEventListener('popstate', this.setCookie);
      window.removeEventListener('hashchange', this.setCookie);
      window.removeEventListener('load', this.setCookie);
      document.removeEventListener('click', this.setCookie);
    }
  }

  setCookie() {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    let domain = OcpUtils.isProd() ? '.ismet.kz' : '.i-smet.kz';
    document.cookie = `lastProjectHref=${encodeURIComponent(window.location.href)}; ${expires}; path=/; domain=${domain}`;
  }
}
