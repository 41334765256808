import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavBarComponent} from "../../components/nav-bar/nav-bar.component";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {FooterComponent} from "../../components/main/footer/footer.component";
import {InlineSVGModule} from "ng-inline-svg-2";
import {DialogModule} from "primeng/dialog";
import {SuffixPipe} from "../../pipes/suffix.pipe";
import { ShortnamePipe } from '../../pipes/shortname.pipe';


@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent,
    SuffixPipe,
    ShortnamePipe
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    SuffixPipe,
    ShortnamePipe
  ],
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    InlineSVGModule,
    DialogModule
  ]
})
export class SharedModule { }
