import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MainService} from "../../../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../../services/auth/auth.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.sass']
})

export class CompanyCardComponent implements OnInit {
  bin: any;
  cardList: any = {list:{content:[]}};
  companyData: any;
  isAuth = false;
  errorNotification: boolean = false;

  constructor(private route: ActivatedRoute, private mainService: MainService,
              private location: Location,
              private authService: AuthService, public translate: TranslateService) {
    this.authService.auth.subscribe(() => this.getInfo());
    this.route.params.subscribe(params => this.bin = params);
  }

  ngOnInit() {
    this.getInfo()
    this.getCompanyData();
    this.getCompanyGoodsById({index: 1});
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
      this.getCompanyGoodsById({index: 0});
    })
  }

  goBack() {
    this.location.back();
  }

  onPageChange(event: any) {
    this.getCompanyGoodsById({index: 0}, event.page, event.rows)
  }

  genInp(item: any) {
    return `url('/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=${item.images[0]?.full_path}'), url('/assets/images/main/card-fill.svg')`
  }

  genInpBanner(item: any) {
    if(item.length > 0){
      return '/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+item
    }else{
      return '/assets/images/main/banner-fill.svg'
    }
  }

  getCompanyGoodsById(event: any, pageNumber = 0, pageSize = 12) {
    if (event.index == 1) {
      this.getCards('GOOD', pageNumber, pageSize)
    }
    if (event.index == 2) {
      this.getCards('SERVICE', pageNumber, pageSize)
    }
    if (event.index == 3) {
      this.cardList = [];
    }
  }

  favorite(itemIndex: any) {
    if (this.cardList.list.content[itemIndex].favorite == true) {
      this.removeFavorite(this.cardList.list.content[itemIndex].id, itemIndex);
    } else {
      this.setFavorite(this.cardList.list.content[itemIndex].id, itemIndex);
    }
  }

  removeFavorite(itemId: any, itemIndex: any) {
    this.mainService.deleteFavorite(itemId).subscribe({
      next: () => {
        this.cardList.list.content[itemIndex].favorite = !this.cardList?.list.content[itemIndex].favorite;
      },
      error: () => {
      }
    });
  }

  setFavorite(itemId: any, itemIndex: any) {
    this.mainService.postFavorite(itemId).subscribe({
      next: () => {
        this.cardList.list.content[itemIndex].favorite = !this.cardList?.list.content[itemIndex].favorite;
      },
      error: () => {
      }
    });
  }

  getCards(categoryType: any = '', pageNumber = 0, pageSize = 12) {
    this.mainService.getAllCardsWithParams('', '', '', categoryType, this.bin.bin, '', '', pageNumber, 5).subscribe({
      next: (res: any) => {
        this.cardList = res;
      },
      error: () => {
      }
    });
  }

  getCompanyData() {
    this.errorNotification = false;
    this.mainService.getPublicCompanyInfo(this.bin.bin).subscribe({
      next: (res: any) => {
        this.companyData = res;
      },
      error: () => {
        this.errorNotification = true;
      }
    });
  }

  addSpacesToNumberString(input: string): string {
    if (input.length <= 3) {
      return input; // Возвращаем строку без изменений, если длина <= 3
    }
    const reversedInput = input.split('').reverse().join(''); // Разворачиваем строку
    const spacedString = reversedInput.replace(/(\d{3})/g, '$1 ').trim(); // Добавляем пробел каждые 3 символа и удаляем лишние пробелы
    return spacedString.split('').reverse().join(''); // Возвращаем развернутую строку обратно
  }
}
