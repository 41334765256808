import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute} from "@angular/router";
import {MainService} from "../../../services/main/main.service";
import {CookieService} from "ngx-cookie-service";
import {TranslateService} from "@ngx-translate/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {HeaderService} from "../../../services/header/header.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-check-edit',
  templateUrl: './card-check.component.html',
  styleUrls: ['./card-check.component.sass'],
  providers: [MessageService]
})
export class CardCheckComponent implements OnInit {

  cardId: any;
  items: MenuItem[] | undefined;
  TagList: any = [];
  cardFormGroup!: FormGroup;
  categoryTypes: any;
  regions: any = [];
  cardData: any = {
    regions: [],
    files: [],
    measurement:{
      id: 0
    }
  };
  feedback: any;
  catCount: any = [0];
  currentTag: any;
  currentCategory: any;
  currentTab: any;
  currentSubCategory: any;
  currentCategoryKz: any;
  currentType: any;
  batchType = [
    {
      id: 1,
      nameRu: 'шт.',
      nameKz: 'шт.'
    },
    {
      id: 2,
      nameRu: 'кг',
      nameKz: 'кг'
    },
    {
      id: 3,
      nameRu: 'г',
      nameKz: 'г'
    },
    {
      id: 4,
      nameRu: 'л',
      nameKz: 'л'
    },
    {
      id: 5,
      nameRu: 'м',
      nameKz: 'м'
    },
    {
      id: 6,
      nameRu: 'м3',
      nameKz: 'м3'
    },
    {
      id: 7,
      nameRu: 'комплекты',
      nameKz: 'комплекты'
    },
  ];
  serviceType = [
    {
      id: 1,
      name_ru: 'Онлайн',
      name_kz: 'Иә',
      active: false
    },
    {
      id: 2,
      name_ru: 'Офлайн',
      name_kz: 'Жоқ',
      active: false
    }
  ];
  order = [
    {
      id: 1,
      name_ru: 'Да, доставляем',
      name_kz: 'Иә',
      active: false
    },
    {
      id: 2,
      name_ru: 'Нет, самовывоз',
      name_kz: 'Жоқ',
      active: false
    }
  ];
  stock = [
    {
      id: 1,
      name_ru: 'В наличии',
      name_kz: 'Иә',
      active: false
    },
    {
      id: 2,
      name_ru: 'Под заказ',
      name_kz: 'Жоқ',
      active: false
    }
  ];
  showCorrectModal = false;
  currentSubCategoryKz: any;
  commentAdded = false;
  tags: any;
  categoryDiadlog: any;
  correctComment: any;
  commentId: any;
  lang: any = 'ru';
  subcategoryDiadlog: any;
  tagEdit = false;
  modalDeleteComment = false;
  commentTextAreas: any = [{comment: ''}];
  deleteCommentTxt = '';
  succes = false;
  catSucces = false;
  showBreadCrumbs = false;
  categories: any;
  subcategories: any;
  manufacturerList: any = [];
  breadcrumb = [{label: 'MainModeratorWindow', routerLink: '/moderator'}, {label: 'CardModeration'}];
  newCategory: any = {
    parent_category_id: 0,
    category_id: 0,
    new_parent_category_name_kz: "string",
    new_parent_category_name_ru: "string",
    new_category_name_kz: "string",
    new_category_name_ru: "string",
    type: "string"
  };
  priceType = [
    {
      nameRu: 'Фиксированная',
      nameKz: 'Тұрақты',
      value: true
    },
    {
      nameRu: 'От',
      nameKz: 'Бастап',
      value: false
    }
  ];
  correctSuccess = false;
  errorNotification: boolean = false;

  constructor(private route: ActivatedRoute, private main: MainService, private cookieService: CookieService,
              private message: MessageService,
              private languageService: HeaderService,
              private sanitizer: DomSanitizer,
              public translate: TranslateService) {
    this.route.params.subscribe(params => this.cardId = params);
  }

  ngOnInit() {
    this.getCardData();
    this.getCommentData();
    this.getTagList();
    this.getModerComment();
    this.initForm();
    this.languageService.languageChange$.subscribe(language => {
      // Обновляем метки хлебных крошек при изменении языка
      this.updateBreadcrumbLabels(language);
    });
    // Обновляем метки хлебных крошек при инициализации компонента
    this.updateBreadcrumbLabels(this.translate.currentLang);
  }

  checkTab(event: any) {
    this.currentTab = event.index;
  }

  updateBreadcrumbLabels(language: string) {
    this.showBreadCrumbs = false
    this.translate.get('MainModeratorWindow', {lang: language}).subscribe((item: any) => {
      this.breadcrumb[0].label = item;
    })
    this.translate.get('CardModeration', {lang: language}).subscribe((item: any) => {
      this.breadcrumb[1].label = item;
    })
    setTimeout(() => {
      this.showBreadCrumbs = true;
    }, 1)
  }

  initForm() {
    this.cardFormGroup = new FormGroup({
      type: new FormControl(this.cardData.type, [Validators.required]),
      vendorCode: new FormControl(this.cardData.vendor_code, [Validators.required]),
      min_order_count: new FormControl(this.cardData.min_order_count, [Validators.required]),
      description_ru: new FormControl(this.cardData.description_ru, [Validators.required]),
      description_kz: new FormControl(this.cardData.description_kz, [Validators.required])
    })
    this.cardFormGroup.get('description_ru')?.setValue(this.cardData.description_ru);
    this.cardFormGroup.get('description_kz')?.setValue(this.cardData.description_kz);
  }

  getManufacturer(event: any){
    this.main.getManufacturer(event).subscribe((res: any) => {
      this.manufacturerList = res;

    })
  }

  getSafeDescription(lang: any): SafeHtml {
    let description = '';
    if (lang == 'ru') {
      description = this.cardData?.description_ru
    } else {
      description = this.cardData?.description_kz;
    }
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  addCategoryDialog(value: any, lang: any, index: any) {
    this.lang = lang;
    this.newCategory.parent_category_id = value.id;
    this.newCategory.category_id = value.child_categories[index].id;
    this.categoryDiadlog = true;
    this.currentCategory = value.name_ru;
    this.currentCategoryKz = value.name_kz;
    this.subCategory(value.id, value.child_categories[index])
  }

  removeCategoryDialog(value: any) {
  }

  close() {
    this.categoryDiadlog = false;
    this.subcategoryDiadlog = false;
    this.tagEdit = false;
    this.showCorrectModal = false;
    this.modalDeleteComment = false;
  }

  addTag(id: any) {
    this.main.addTag({id: this.currentTag}).subscribe({
      next: () => {
        this.message.add({severity: 'success', summary: 'Success', detail: 'Тэг добавлен', life: 4000});
        this.close();
        this.getCardData();
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  addCategory() {
    this.newCategory.new_category_name_ru = this.currentSubCategory;
    this.newCategory.new_category_name_kz = this.currentSubCategoryKz;
    this.newCategory.new_parent_category_name_ru = this.currentCategory;
    this.newCategory.new_parent_category_name_kz = this.currentCategoryKz;
    this.newCategory.type = this.cardFormGroup.get('type')?.value;
    this.addToSpr();

  }

  feedbackToPublic(id: any) {
    this.main.addFeedbackToPublic({id: id}).subscribe({
      next: () => {
        // @ts-ignore
        window['yaCounter48820577'].reachGoal('obshee-kolichestvo-otzivov');
        this.getModerComment();
        this.commentAdded = true;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  addToSpr() {
    this.main.addNewCategory(this.newCategory).subscribe({
      next: () => {
        this.getCardData();
        this.close();
        this.catSucces = true;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({severity: 'error', summary: 'Success', detail: err.error.description_ru, life: 4000});
      }
    });
  }

  getCommentData() {
    this.main.getCardComments(this.cardId.id).subscribe({
      next: (res: any) => {
        this.commentTextAreas = res;
      },
      complete: () => {
      },
      error: () => {
      }
    })
  }

  subCategory(id: any, value: any) {
    this.main.getSubCategory(id).subscribe({
      next: (res: any) => {
        this.subcategories = res.child_categories;
        setTimeout(() => {
          this.currentSubCategory = value.name_ru;
          this.currentSubCategoryKz = value.name_kz;
        }, 10)
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  getCategoryByType() {
    this.main.getCatalogCategoriesInternal(this.cardFormGroup.get('type')?.value).subscribe({
      next: (res: any) => {
        this.categories = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  getCardData() {
    this.errorNotification = false;
    this.main.getCardDataForCabinet(this.cardId.id).subscribe({
      next: (res: any) => {
        this.cardData = res;
        this.getManufacturer(this.cardData?.parent_categories[0]?.child_categories[0]?.id);
        this.order[res.delivery-1].active = true;



        if(res.stock_or_order){
          this.stock[res.stock_or_order-1].active = true;
        }

        this.initForm();
        this.getTypesOfCategories();
        res.parent_categories.forEach((element: any) => {
          this.catCount.push(element.child_categories.length)
        });
        this.TagList = [];
        res.tags.forEach((element: any) => {
          if (element.is_active) {
            element.disabled = true;
          }
          this.TagList.push(element);
        });
      },
      complete: () => {
      },
      error: () => {
        this.errorNotification = true;
      }
    });
  }

  getModerComment() {
    this.main.getModerComments(this.cardId.id).subscribe({
      next: (res: any) => {
        this.feedback = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  toCorrect() {
    this.main.cardToCorrect({id: this.cardId.id, comment: this.correctComment}).subscribe({
      next: () => {
        this.close();
        this.correctSuccess = true;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  deleteComment() {
    this.main.deleteComment({feedback_id: this.commentId, deleted_comment: this.deleteCommentTxt}).subscribe({
      next: () => {
        this.getModerComment();
        this.close();
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  toPublic() {
    this.main.cardToPublic(this.cardId.id).subscribe({
      next: () => {
        this.succes = true;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({severity: 'error', summary: 'Success', detail: err.error.description_ru, life: 4000});
      }
    });
  }

  removeComment(id: any) {
    this.modalDeleteComment = true;
    this.commentId = id;
  }

  getTypesOfCategories() {
    this.main.getAllCategoryTypes().subscribe({
      next: (res: any) => {
        this.categoryTypes = res;
        if(this.cardData.type == 'GOOD'){
          this.categoryTypes[0].active = true;
          this.currentType = this.categoryTypes[0];
        }else{
          this.categoryTypes[1].active = true;
          this.currentType = this.categoryTypes[1];
        }
        if(this.categories?.length == 0){
          this.getCategoryByType();
        }
      },
      error: () => {
      }
    });
  }

  getTagList() {
    this.main.getTags().subscribe({
      next: (res: any) => {
        this.tags = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }
}
