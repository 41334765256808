<div class="company-block-wrap">
  <div class="white-ui-block">
    <div class="company-header flex">
      <div class="avatar-block">
        <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+companyData?.avatar_img_url" alt="">
      </div>
      <div class="company-header-info">
        <div class="company-name">
          {{ (translate.currentLang == 'ru' ? companyData?.name_ru : companyData?.name_kz) | shortname }}
        </div>
        <div class="company-bin">
          {{ 'IINBIN' | translate }}: {{ companyData?.bin }}
        </div>
        <div class="company-rating">
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4513 12.963C9.98879 13.3089 7.0212 11.1163 6.45284 11.1115C5.88448 11.1067 2.88349 13.2488 2.42639 12.8952C1.96928 12.5415 3.04391 8.9091 2.87265 8.34171C2.7014 7.77432 -0.171872 5.44806 0.00811114 4.88363C0.188134 4.31919 3.81987 4.26676 4.28237 3.92088C4.74487 3.57503 5.97011 -0.00477262 6.53851 4.77737e-06C7.10683 0.00482372 8.27672 3.60486 8.73382 3.95851C9.19093 4.31213 12.8214 4.42595 12.9927 4.99334C13.164 5.56073 10.2553 7.83813 10.0753 8.40257C9.89527 8.96701 10.9138 12.6171 10.4513 12.963Z" fill="#FFCE31"/>
          </svg>

          4.5<span class="review-count">5 отзывов</span>
        </div>
        <div class="tag-list flex">
          <div class="company-tag">
            Кафе
          </div>
          <div class="company-tag">
            Гостиницы
          </div>
          <div class="company-tag">
            Банкетные залы
          </div>
          <div class="company-tag">
            Банкетные залы
          </div>
          <div class="company-tag">
            +3
          </div>
        </div>
        <div class="social-list">
          <img src="assets/images/social/insta.svg" alt="">
          <img src="assets/images/social/wp.png" alt="">
          <img src="assets/images/social/facebook.svg" alt="">
          <img src="assets/images/social/x.png" alt="">
          <img src="assets/images/social/telegramm.svg" alt="">
          <img src="assets/images/social/youtube.svg" alt="">
          <img src="assets/images/social/tiktok.png" alt="">
          <img src="assets/images/social/vk.png" alt="">
          <img src="assets/images/social/ok.png" alt="">
        </div>
      </div>
      <div class="company-btn-group ml-auto">
        <div class="secondary-btn">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7914 12.6082C21.0355 12.3989 21.1575 12.2943 21.2023 12.1698C21.2415 12.0605 21.2415 11.941 21.2023 11.8317C21.1575 11.7072 21.0355 11.6026 20.7914 11.3934L12.3206 4.13275C11.9004 3.77256 11.6903 3.59246 11.5124 3.58804C11.3578 3.58421 11.2101 3.65213 11.1124 3.77201C11 3.90995 11 4.18668 11 4.74016V9.03541C8.86532 9.40887 6.91159 10.4905 5.45971 12.1146C3.87682 13.8853 3.00123 16.1767 3 18.5517V19.1637C4.04934 17.8996 5.35951 16.8773 6.84076 16.1667C8.1467 15.5402 9.55842 15.1691 11 15.0713V19.2614C11 19.8149 11 20.0916 11.1124 20.2296C11.2101 20.3494 11.3578 20.4174 11.5124 20.4135C11.6903 20.4091 11.9004 20.229 12.3206 19.8688L20.7914 12.6082Z" stroke="#8D959D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          Поделиться
        </div>
        <div class="secondary-btn">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3036 4.67974C20.2179 3.60425 18.7452 3 17.2095 3C15.6738 3 14.201 3.60425 13.1153 4.67974L11.9997 5.7844L10.8841 4.67974C8.62297 2.44083 4.95697 2.44083 2.69584 4.67974C0.434719 6.91865 0.434719 10.5486 2.69584 12.7875L3.81147 13.8922L11.9997 22L20.188 13.8922L21.3036 12.7875C22.3898 11.7126 23 10.2543 23 8.73364C23 7.21302 22.3898 5.75472 21.3036 4.67974Z" stroke="#8D959D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          В контрагенты
        </div>
        <div class="secondary-btn">

          Это моя компания
        </div>
      </div>
    </div>

  </div>
  <div class="white-ui-block">
    <div class="block-header">
      О компании
    </div>
    <div class="">
      {{ translate.currentLang == 'ru' ? companyData?.description_ru : companyData?.description_kz }}
    </div>
  </div>
  <div class="white-ui-block">
    <div class="header-wrap">
      <div class="block-header">
        Товары и услуги
      </div>
      <div class="goods-count">
        Все 2000 товаров и услуг
      </div>
    </div>
    <div class="flex">
      <div class="goods-empty-wrap" *ngIf="cardList?.list?.content?.length != 0">
        <div class="goods-empty-header">
          Пока пусто!
        </div>
        <div class="goods-empty-description">
          Похоже, компания еще не успела добавить товары или услуги. Загляните позже!
        </div>
      </div>
      <div class="cards-wrap" *ngIf="cardList?.list?.content?.length == 0">
        <app-card-template *ngFor="let item of cardList?.list?.content; index as i" [cardInfo]="item" [cardIndex]="i" [isAuth]="isAuth"></app-card-template>
      </div>
    </div>
  </div>
  <div class="white-ui-block">
    <div class="block-header">
      Инфо
    </div>
    <div class="flex gap-5">
      <div class="contact-data">
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Адрес
          </div>
          <div class="">
            Микрорайон Каргалы, улица Кенесары хан, дом 83/6, н.п. 110
          </div>
        </div>
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Почта
          </div>
          <div class="">
            sales@liberty-group.kz
          </div>
        </div>
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Телефон
          </div>
          <div class="">
            +7 777 123 45 67
          </div>
        </div>
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Ватсап
          </div>
          <div class="">
            +7 777 123 45 67
          </div>
        </div>
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Телеграм
          </div>
          <div class="">
            +7 777 123 45 67
          </div>
        </div>
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Сайт
          </div>
          <div class="">
            liberty-group.kz
          </div>
        </div>
        <div class="flex mb-3">
          <div class="w-7rem data-name">
            Время работы
          </div>
          <div class="">
            Ежедневно с 9:00 до 18:00
          </div>
        </div>

      </div>
      <div class="map">
        КАРТА
      </div>
    </div>
    <div class="additional">
      Посмотреть другие филиалы
    </div>
  </div>
  <div class="white-ui-block">
    <div class="header-wrap">
      <div class="block-header">
        Отзывы
      </div>
      <div class="goods-count">
        Все 2000 отзывов
      </div>
    </div>
  </div>
  <div class="white-ui-block">
    <div class="block-header">
      Доставка и оплата
    </div>
    <div class="">
      <div class="shipping">
        Самовывоз
      </div>
      <div class="">
        Забрать свой заказ вы можете по адресу:<br>
        050000, Алматы, Рыскулова, 103 В, уг. ул. Биянху, Т/ц "Строй Сити", бутик В 105
      </div>
    </div>
    <div class="white-ui-block-content-limit mt-3">
      <div class="shipping">
        Транспортная компания
      </div>
      <div class="">
        Стоимость доставки транспортной компанией осуществляется за счет покупателя. Стоимость доставки рассчитывается в индивидуальному порядку и зависит от объёма заказа.
      </div>
    </div>
  </div>
<!--  <div (click)="goBack()" class="flex back-wrap w-fit">-->
<!--    <div class="left-arrow cursor-pointer"></div>-->
<!--    <div class="back-txt cursor-pointer">{{ 'Back' | translate}}</div>-->
<!--  </div>-->
<!--  <ng-container *ngIf="!errorNotification">-->
<!--    <div class="company-banner">-->
<!--      <img [src]="genInpBanner(companyData?.banner_img_url)" alt="">-->
<!--    </div>-->
<!--    <div class="relative company-header-wrap">-->
<!--      <div class="company-logo">-->
<!--        <img [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+companyData?.avatar_img_url" alt="">-->
<!--      </div>-->
<!--      <div class="company-description">-->
<!--        <div class="company-name">-->
<!--          {{ (translate.currentLang == 'ru' ? companyData?.name_ru : companyData?.name_kz) | shortname }} -->
<!--        </div>-->
<!--        <div class="bin-info">-->
<!--          {{ 'IINBIN' | translate }}: {{ companyData?.bin }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->

<!--      <p-tabView (onChange)="getCompanyGoodsById($event)">-->
<!--        <p-tabPanel [header]="'AboutUs' | translate">-->
<!--          <div>-->
<!--            <div class="description-header">{{ 'Description' | translate }}</div>-->
<!--            <div class="description-txt">-->
<!--              {{ translate.currentLang == 'ru' ? companyData?.description_ru : companyData?.description_kz }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="description-header">{{ 'Address' | translate }}</div>-->
<!--            <div class="description-txt">-->
<!--              {{ translate.currentLang == 'ru' ? companyData?.street : companyData?.street }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="description-header">{{ 'Contacts' | translate }}</div>-->
<!--            <div class="description-txt">-->
<!--              Email: <a href="mailto:{{ companyData?.email }}" target="_blank">{{ companyData?.email }}</a>-->
<!--            </div>-->
<!--            <div class="description-txt">-->
<!--              {{ 'PhoneNumber' | translate }}: <a href="tel:{{ companyData?.phone }}">{{ companyData?.phone }}</a>-->
<!--            </div>-->
<!--            <div class="description-txt">-->
<!--              {{ 'Whatsapp' | translate }}: <a href="https://wa.me/{{ companyData?.whatsapp }}" target="_blank">{{ companyData?.whatsapp }}</a>-->
<!--            </div>-->
<!--            <div *ngIf="companyData?.telegram" class="description-txt">-->
<!--              {{ 'Telegram' | translate }}: <a href="https://t.me/{{ companyData?.telegram }}" target="_blank">{{ companyData?.telegram }}</a>-->
<!--            </div>-->
<!--            <div *ngIf="companyData?.instagram" class="description-txt">-->
<!--              {{ 'Instagram' | translate }}: <a href="https://www.instagram.com/{{ companyData?.instagram }}/" target="_blank">{{ companyData?.instagram }}</a>-->
<!--            </div>-->
<!--            <div *ngIf="companyData?.website" class="description-txt">-->
<!--              {{ 'Site' | translate }}: <a href="{{ companyData?.website }}" target="_blank">{{ companyData?.website }}</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </p-tabPanel>-->
<!--        <p-tabPanel [header]="'Goods' | translate">-->
<!--          <div>-->
<!--            <div class="goods-items-wrap flex">-->
<!--              <app-card-template *ngFor="let item of cardList?.list?.content; index as i" [cardInfo]="item" [cardIndex]="i" [isAuth]="isAuth"></app-card-template>-->
<!--            </div>-->
<!--          </div>-->
<!--        </p-tabPanel>-->
<!--        <p-tabPanel [header]="'Services' | translate" >-->
<!--          <div>-->
<!--            <div class="goods-items-wrap flex">-->
<!--              <app-card-template *ngFor="let item of cardList?.list?.content; index as i" [cardInfo]="item" [cardIndex]="i" [isAuth]="isAuth"></app-card-template>-->
<!--            </div>-->
<!--          </div>-->
<!--        </p-tabPanel>-->


<!--      </p-tabView>-->
<!--    </div>-->
<!--    <div *ngIf="cardList.totalElements > 12" class="flex align-items-center justify-content-center">-->
<!--      <div class="flex-1">-->
<!--        <p-paginator (onPageChange)="onPageChange($event)" [first]="0" [rows]="12" [totalRecords]="cardList?.totalElements" [showFirstLastIcon]="false"></p-paginator>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->
<!--  <ng-container *ngIf="errorNotification">-->
<!--    <div class="block-no-content">-->
<!--      <div inlineSVG="assets/images/main/no-moderation.svg"></div>-->
<!--      <div class="text-block">-->
<!--        <div class="text-no-content">-->
<!--          {{'noCompanyWithTheSameBINIINWasFoundToFindTheNecessaryInformationUseTheNavigationToolsProvidedOnTheSiteYouCanReturnTo' | translate}}-->
<!--          <a href="/" class="text-no-content-blue">{{'mainPageOfThe1' | translate}}-->
<!--            <span *ngIf="translate.currentLang != 'ru'" class="text-no-content">{{'оралуыңызға болады.'}}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->
</div>

